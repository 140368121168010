body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.bg-loader-norme {
    position: fixed !important;
    left: 0% !important;
    top: 0% !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 9998 !important;
    background-color: rgba(255, 255, 255, 0.85);
}

.loader-norme {
    position: fixed;
    left: 50%;
    top: 45%;
    color: rgba(0, 0, 255, 1);
    z-index: 9999;
}

.tree-view-item__list {
    padding-left: 10px !important;
}

.icon--size-small {
    line-height: inherit !important;
}

.u-list__item {
    font-size: 17px !important;
}

.ot-floating-button__close {
    margin-left: 12px !important;
}

.ot-floating-button__open {
    margin-left: 10px !important;
}

.search-highlight, mark {
    background-color: rgb(255, 211, 67);
    padding: 2px;
    border-radius: 6px;
    font-style: italic;
}

.paginator__item.paginator__item--active {
    background-color: rgb(var(--brand-maggioli-04)) !important;
}

.actions-bar {
    right: 10px !important;
  }

td.ot-host-td, td.ot-cookies-td, td.ot-cookies-type, td.ot-life-span-td, th.ot-table-header {
border-style: solid;
border-width: 1px;
padding: 7px;
border-color: rgb(207, 207, 207);
}

td.ot-host-td > * {
    text-decoration: underline;
}
